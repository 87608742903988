import { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Formik } from "formik";
import { API } from "../../api/auth/auth";
import { STOREURLS } from "../../api/auth/urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from 'react-bootstrap';



const NewCategory = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  return (
    <Fragment>
      <Breadcrumb title="Nouvelle catégorie" parent="Catégories" />
      <div className="container-fluid search-page">
        <div className="mt-4">
          <div>
            <div className="card">
              <div className="p-4">
                <Formik
                  initialValues={{
                    title: "",
                    description: "",
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    const title = values.title;
                    const description = values.description;
                    console.log(values.title);
                    API.post(STOREURLS.CATEGORIES.create, {title, description}, {headers: {"Content-Type": "application/json"}}).then((resp) => {
                        toast.success("Catégorie créée avec succès!")
                        setSubmitting(false);
                    }).catch((error) => {
                        setSubmitting(false);
                    })
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form className="theme-form" onSubmit={handleSubmit}>
                      <div className="form-row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">{"Titre"}</label>
                            <input
                              className="form-control"
                              type="text"
                              id="title"
                              name="title"
                              placeholder="Microsoft Office 2013"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              {"Description"}
                            </label>
                            <textarea
                              name="description"
                              id="description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                              required
                              cols="30"
                              rows="5"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-12">
                          <div className="form-group">
                            <button disabled={isSubmitting} className="btn btn-primary" type="submit">
                              Créer la catégorie
                              {" "}
                              {isSubmitting && <Spinner animation="border" size="sm"/>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewCategory;
