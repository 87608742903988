import produce from "immer";
import { LOGIN_FAILED, LOGIN_REQUEST, LOGIN_SUCCESS } from "./action";



const initialState = {
    loading: {
        login_failed: false,
        login_request: false,
        login_success: false
    },
    user_data: undefined
}

const authReducer = (state = initialState, action) => {

    switch(action.type) {
        case LOGIN_REQUEST:
            return produce(state, (draft) => {
                draft.loading.login_request = true;
                draft.loading.login_success = false;
                draft.loading.login_failed = false;
            })
        case LOGIN_SUCCESS:
            return produce(state, (draft) => {
                const {payload} = action;
                draft.loading.login_request = false;
                draft.loading.login_success = true;
                draft.loading.login_failed = false;
                draft.user_data = payload;
            })
        case LOGIN_FAILED:
            return produce(state, (draft) => {
                draft.loading.login_request = false;
                draft.loading.login_success = false;
                draft.loading.login_failed = true;
            })
        default:
            return state
    }
}

export default authReducer;