import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { useNavigate } from 'react-router-dom';
import { CONTACTAPI } from '../../api/contact';
import { toast } from 'react-toastify';

import { Eye, Trash } from 'react-feather';
import SweetAlert from 'sweetalert2';
import { Spinner, Table } from 'reactstrap';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const Newsletters = () => {
    const navigate = useNavigate();

    const [newsletters, setNewsletters] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [toDelete, setToDelete] = useState([]);
    const [contact, setContact] = useState(undefined);

    const handleDeleteSubscription = (event, subsc) => {
        event.preventDefault();
        setContact(subsc);
        
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this Training subscription!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                // setIsDeleting(subsc.id);
                setToDelete(subsc.id);
                CONTACTAPI.delete(`newsletter/${subsc.id}/`).then((resp) => {
                
                    setTimeout(() => {
                        toast.success("La souscription a bien ete supprimee");
                    }, 200);
                    const newArr = toDelete.filter(item => item != subsc.id);
                    setToDelete(newArr);
                    
                    
                    // Remove deleted idtem from array

                    const newSubscriptionsArray = newsletters.filter(item => item.id != subsc.id);
                    setNewsletters(newSubscriptionsArray);
                    // navigate(`${process.env.PUBLIC_URL}/jobSearch/jobs`);
                    SweetAlert.fire(
                    'Deleted!',
                    'The Subscription has been deleted.',
                    'success'
                    )
                }).catch(err => {
                    setTimeout(() => {
                        toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
                    }, 200);
                    // setIsDeleting(false);
                    const newArr = toDelete.filter(item => item != subsc.id);
                    setToDelete(newArr);
                })
            }
            else {
                SweetAlert.fire(
                'Your Subscription is safe!'
                )
            }
        })
    }

    useEffect(() => {

        CONTACTAPI.get("newsletter/").then((resp) => {
            // console.log(resp)
            // fetch job applications 
            
            let results = resp.data.results;
            let copy = resp.data.results;
            
            setNewsletters(results);
            console.log("ffldkfldklkfdl===>", copy);
            setIsLoading(false);
        }).catch(err => {
            setTimeout(() => {
                toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
            }, 200);
            setIsLoading(false);
        });
    }, [])

    return (
        <Fragment>
            <Breadcrumb title="Newsletter subscribers list" parent="Contact" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 xl-60">
                        <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>{"Newsletters"}</h5>
                                </div>
                                <div className="card-block row">
                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                        <div className="table-responsive">
                                            {
                                                isLoading && <Box sx={{ width: '100%', marginBottom: '1rem' }}>
                                                    <LinearProgress />
                                                </Box>
                                            }
                                            <table className="table table-hover">
                                                <caption>{"List of Newsletter subscribers"}</caption>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{"#"}</th>
                                                        <th scope="col">{"Name"}</th>
                                                        <th scope="col">{"Email"}</th>
                                                        <th scope="col">{"Action"}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        !isLoading && newsletters.length === 0 && <p className="text-center">{"Any data"}</p>
                                                    }
                                                    {
                                                        !isLoading && newsletters.map((newsletter, index) => {

                                                            return(
                                                                <tr key={`application-${newsletter.id}`}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{newsletter.name}</td>
                                                                    <td>{newsletter.email}</td>
                                                                    <td>
                                                                        {/* <a href={`#`} onClick={(event) => handleShowDetails(event, contact)} ><Eye/></a> */}
                                                                        {/* {' '} */}
                                                                        {/* <a href='#'><i data-feather="eye"></i></a>
                                                                        <a href='#'><i data-feather="eye"></i></a> */}
                                                                        { !toDelete.includes(newsletter.id) ? <a href={`#`} onClick={(event) => handleDeleteSubscription(event, newsletter)}><Trash color='red'/></a> :
                                                                        <Spinner style={{color: 'red'}} size={10}/>}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Newsletters;