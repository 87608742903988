import { AUTHAPI } from "./auth/auth"
import { AUTHURLS } from "./auth/urls"




export const refreshToken = async () => {

    const token = localStorage.getItem("refresh_token");

    const newToken = await AUTHAPI.post(AUTHURLS.refresh_token, { "refresh_token": token } ).then(resp => {

        localStorage.setItem("access_token", resp.data.access_token)
        console.log("access_token", resp.data.access_token);

        return resp.data.access_token;
    });

    return newToken;
}