/* TODO APP*/

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOAD_USERS_DATA = "LOAD_USERS_DATA";


// export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
// export const MARK_ALL_ITEMS = "MARK_ALL_ITEMS";
// export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
// export const REMOVE_ITEM = "REMOVE_ITEM";
// export const SELECTED_ITEM = "SELECTED_ITEM";


export const loginRequest = () => {
    return ({
        type: LOGIN_REQUEST,
    })
}

export const loginSuccess = (userData) => {
    return ({
        type: LOGIN_SUCCESS,
        payload: {userData}

    })
}


export const loginFailed = () => {
    return ({
        type: LOGIN_FAILED

    })
}


