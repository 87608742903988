import React, { Fragment, useState ,useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Submit} from "../../constant";
import axios from 'axios'
import { Formik, Form, Field } from 'formik';
import { RECRUITMENTAPI } from '../../api/axios';
import { toast } from 'react-toastify';


const NewJobCategory = () => { 

    const pathname = window.location.pathname;
    const pathId = pathname.substring(pathname.lastIndexOf('/') + 1);
    const id = pathId ? pathId :1
    const [image, setImage] = useState(null);
    const [singleData, setSingleData] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.PUBLIC_URL}/api/jobSearch.json`).then(res => {
            res.data.filter((data) => {
                if (data.Id == id) {
                    setSingleData(data)
                }
                return 0;
            })
        }) 
    }, [id]);


    const initialValues = {
        categoryName: "",
        categoryNameEn: "",
        categoryNameFr: "",
    }

    const handleSubmit = (values) => {
        const data = new FormData();
        data.append("name", values.categoryName);
        data.append("name_en", values.categoryNameEn);
        data.append("name_fr", values.categoryNameFr);
        // console.log(data)
        RECRUITMENTAPI.post("categories/", data).then((resp) => {
            // console.log(resp)
            setTimeout(() => {
                toast.success("La categorie a bien ete creee");
            }, 200);
        }).catch(err => {
            console.log(err)
            setTimeout(() => {
                toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
            }, 200);
        })
    }

    

    return (
        <Fragment>
            <Breadcrumb title="New job" parent="Jobs" />
                        <div className="container-fluid">
                            <div className="row">
                                {/* <JobFilter /> */}
                                <div className="col-xl-9 xl-60">
                                    <div className="card">
                                        <div className="job-search">
                                            <div className="card-body pb-0">
                                                {/* <div className="d-flex align-items-start">
                                                    <img className="img-40 img-fluid m-r-20" src={one} alt="" />
                                                    <div className="flex-grow-1">
                                                        <h6 className="f-w-600">
                                                            <a href="#javascript">{singleData.job_name}</a>
                                                            <span className="pull-right">
                                                                <button className="btn btn-primary" type="button"><span><i className="fa fa-check text-white"></i></span> {"Save this job"}</button></span></h6>
                                                            <p>{singleData.job_area} && {singleData.job_city}<span>
                                                                <i className="fa fa-star font-warning"></i>
                                                                <i className="fa fa-star font-warning"></i>
                                                                <i className="fa fa-star font-warning"></i>
                                                                <i className="fa fa-star font-warning"></i>
                                                                <i className="fa fa-star font-warning"></i>
                                                                </span>
                                                            </p>
                                                    </div>
                                                </div> */}
                                                <div className="job-description">
                                                <Formik
                                                    initialValues={initialValues}
                                                    onSubmit={(values) => handleSubmit(values)}
                                                >
                                                    {
                                                        ({resetForm}) => (
                                                            <Form className="form theme-form">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className="form-group">
                                                                            <label htmlFor="categoryName">{"Job category name"}:<span className="font-danger">{"*"}</span></label>
                                                                            <Field className="form-control" id="categoryName" name="categoryName" type="text" placeholder="eg: Marketing" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className="form-group">
                                                                            <label htmlFor="categoryName">{"Job category name en"}:<span className="font-danger">{"*"}</span></label>
                                                                            <Field className="form-control" id="categoryNameEn" name="categoryNameEn" type="text" placeholder="eg: Marketing" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className="form-group">
                                                                            <label htmlFor="categoryName">{"Job category name fr"}:<span className="font-danger">{"*"}</span></label>
                                                                            <Field className="form-control" id="categoryNameFr" name="categoryNameFr" type="text" placeholder="eg: Marketing" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="row">
                                                                    <div className="col">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label pt-0">{"Upload category image"}:</label>
                                                                            <input className="form-control" onChange={handleChange} type="file" />
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                
                                                                <div className="card-footer">
                                                                    <button className="btn btn-primary me-1" type="submit">{Submit}</button>
                                                                    {/* <Field className="btn btn-light" type="reset" value="Cancel" /> */}
                                                                </div>
                                                            </Form>
                                                        )
                                                    }
                                                </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
        </Fragment>
    );
};

export default NewJobCategory;