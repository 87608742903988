import { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Link } from "react-router-dom";
import { API } from "../../api/auth/auth";
import { STOREURLS } from "../../api/auth/urls";
import { Edit, Trash } from "react-feather";

const Articles = () => {

  const [articles, setArticles] = useState()

    useEffect(() => {
      API.get(STOREURLS.ARTICLES.list).then((resp) => {
        const { data } = resp;
        setArticles(data)
      })
    }, []);
  return (
    <Fragment>
      <Breadcrumb title="Articles" parent="Store" />
      <div className="container-fluid search-page">
        <div className="d-flex">
          <div className="ml-auto">
            <Link to="/store/articles/new" className="btn btn-primary">Nouvel article</Link>
          </div>
        </div>
        <div className="mt-4">
          <div className="card p-4">
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Titre</th>
                  <th scope="col">Prix (XAF)</th>
                  <th scope="col">Catégorie</th>
                  <th scope="col">Boutique</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  articles?.results.map((article, index) => (
                    <tr key={article.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{article.title}</td>
                      <td>{article.price}</td>
                      <td>{article.category.title}</td>
                      <td>{article.store}</td>
                      <td>
                        <Link to="#" className="btn p-0 mr-2" style={{marginRight: "1rem"}}>
                          <Edit fontSize={"12px"} size={"12px"} />
                        </Link>
                        <Link to="#" className="btn p-0 mr-2">
                          <Trash fontSize={"12px"} size={"12px"} />
                        </Link>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Articles;
