import axios from 'axios';
import { refreshToken } from './refreshAccessToken';


const token = localStorage.getItem('access_token');
let authBaseUrl = 'https://api.ova-consulting.fr';
let baseUrl = 'https://api.ova-consulting.fr';
// authBaseUrl = 'http://192.168.1.7:8001'
// baseUrl = 'http://192.168.1.7:8000'

const AUTHAPI = axios.create({
    baseURL: authBaseUrl,
    headers: {
        "Authorization": token
    }
    // timeout: 10000,
});

const API = axios.create({
    baseURL: baseUrl,
    headers: {
        "Authorization": token
    }
    // timeout: 10000,
});

// Interceptors


// Request interceptor for API calls
API.interceptors.request.use(
    async config => {
      config.headers = { 
        'Authorization': `${token}`,
      }
      return config;
    },
    error => {
      Promise.reject(error)
  });
  
  // Response interceptor for API calls
  API.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshToken();
      API.defaults.headers.common['Authorization'] = '' + access_token;
      return API(originalRequest);
    }
    return Promise.reject(error);
  });


export { API, AUTHAPI }