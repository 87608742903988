import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AUTHAPI } from "../api/auth/auth"
import { AUTHURLS } from "../api/auth/urls";
import { loginSuccess } from "../redux/auth/action";



export default function Auth (props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const access_token = localStorage.getItem("access_token");
    const dispatch = useDispatch()

    const getUserInfo = () => {

        if(user && access_token) {

            AUTHAPI.get(AUTHURLS.userInfo(user.id)).then(resp => {

                const { data } = resp;

                dispatch(
                    loginSuccess(data)
                )
            })
        }
    }

    useEffect(() => {
        console.log("sdklsd============>", user)
        getUserInfo()
    }, [])

    return props.children
}