import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Button, Input, Spinner } from 'reactstrap';
import Skeleton from '@mui/material/Skeleton';
import { RECRUITMENTAPI } from '../../api/axios';
import SweetAlert from 'sweetalert2';
import {useForm} from 'react-hook-form';
import { Trash2, Edit2 } from 'react-feather';
import { toast } from 'react-toastify';

const JobCategories = () => {

    const [editRow, setEditRow] = useState(null);
    const [JobCategories, setJobCategories] = useState([]);
    const [editModal, seteditModal] = useState(false);
    const [isEditting, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    const { register, handleSubmit, formState: { errors } } = useForm();
 
    const editToggle = () => { seteditModal(!editModal) }

    const editbookmarkdata = (data) => {
        editToggle();
        setEditRow(data);
    }

    const Removefrombookmark = (data) => {
        SweetAlert.fire({
          title: 'Are you sure?',
          text: "Once deleted, you will not be able to recover this category!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          cancelButtonText: 'cancel',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
              setIsDeleting(data.id);
              RECRUITMENTAPI.delete(`categories/${data.id}/`).then((resp) => {
                const newCats = JobCategories.filter(category => {
                    return category.id !== data.id
                })
                setJobCategories(newCats);
                setTimeout(() => {
                    toast.success("La categorie a bien ete supprimee");
                }, 200);
                setIsDeleting(null);
            }).catch(err => {
                console.log(err)
                setTimeout(() => {
                    toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
                }, 200);
                setIsEditing(false);
            })
            SweetAlert.fire(
              'Deleted!',
              'The category has been deleted.',
              'success'
            )
          }
          else {
            SweetAlert.fire(
              'Your category is safe!'
            )
          }
        })
    }


    const onSubmit = data => {
        // alert("lkllklk");
        if (data !== '') {
            setIsEditing(true)
            // console.log("kflkldklf", data)
            // Addnewbookmark(data, "lightgallry/01.jpg");
            // setaddModal(false)
            
            RECRUITMENTAPI.patch(`categories/${editRow.id}/`, data).then((resp) => {
                const newCats = JobCategories.map(cat => {
                    // console.log("data ===>", data);
                    if (cat.id === editRow?.id) {
                        cat.name = data.name
                        cat.name_en = data.name_en
                        cat.name_fr = data.name_fr
                    }
                    return cat
                })
                setJobCategories(newCats);
                setTimeout(() => {
                    toast.success("La categorie a bien ete modifiee");
                }, 200);
                setIsEditing(false);
                
            }).catch(err => {
                console.log(err)
                setTimeout(() => {
                    toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
                }, 200);
                setIsEditing(false);
            })
        } else {
        errors.showMessages();
        }
    };

    const handleEdit = (e) => {
        // console.log("a");
        handleSubmit(onSubmit)();
        // alert("b");
        // e.preventDefault();
    }

    useEffect(() => {
        RECRUITMENTAPI.get("categories/").then((resp) => {
            setJobCategories(resp.data.results);
            setIsLoading(false);
        }).catch(err => {

            setIsLoading(false);
        })
        // axios.get(`${process.env.PUBLIC_URL}/api/jobSearch.json`).then(res => setJobData(res.data))
    }, [])

    return (
        <Fragment>
            <Breadcrumb title="Jobs" parent="Job Categories" />
            <div className="container-fluid">
                <div className="row">
                    {/* <JobFilter /> */}
                    <div className="col-xl-9 xl-60">
                        <div className="row">
                            {
                                isLoading && [1, 2, 3, 4, 5, 6].map(item => {

                                    return (
                                        <div className="col-xl-6 xl-100 mb-3" key={item}>
                                            <Skeleton variant="rounded" height={60} />
                                        </div>
                                    )
                                })
                            }
                            {!isLoading && JobCategories.map((data, i) => {
                                return (
                                    // <div className="col-xl-6 xl-100" key={data.id}>
                                    //     <div className={`card card ${'ribbon-vertical-left-wrapper'}`}>
                                    //         <div className="job-search">
                                    //             <div className="card-body">
                                    //                 <div className="d-flex align-items-start">
                                    //                     <img className="img-40 img-fluid m-r-20" src={""} alt="" />
                                    //                     <div className="flex-grow-1">
                                    //                         <h6 className="f-w-600">
                                    //                             <a onClick={() => clickApply(data)} href={`#${data.id}`}>
                                    //                                 {data.name}
                                    //                             </a>
                                    //                             {/* {(data.badgeType === 'primary' ?
                                    //                                 <span className="badge badge-primary pull-right">
                                    //                                     {data.badgeValue}
                                    //                                 </span>
                                    //                                 : <span className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary">
                                    //                                     <i className="icofont icofont-love">{data.badgeValue}</i>
                                    //                                 </span>
                                    //                             )} */}
                                    //                         </h6>
                                    //                         <p>{data.job_area}, {data.job_city}
                                    //                             <span>
                                    //                                 <i className="fa fa-star font-warning"></i>
                                    //                                 <i className="fa fa-star font-warning"></i>
                                    //                                 <i className="fa fa-star font-warning"></i>
                                    //                                 <i className="fa fa-star font-warning"></i>
                                    //                                 <i className="fa fa-star font-warning"></i>
                                    //                             </span>
                                    //                         </p>
                                    //                     </div>
                                    //                 </div>
                                    //                 {/* <p>{data?.Job_description ?? ""}</p> */}
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                    <div className="col-xl-6 xl-100" key={i}>
                                        <div className="card">
                                            <div className="job-search">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center">
                                                        {/* <img className="img-40 img-fluid m-r-20" src={require(`../../assets/images/${data.logo}`)} alt="" /> */}
                                                        <div className="flex-grow-1">
                                                            <h6 className="f-w-600"><a href="#javascript">{data.name}</a>
                                                            </h6>
                                                        </div>
                                                        <div className="">
                                                            <ul className="d-flex">
                                                                <li><a href={`#${data.id}`} style={{marginRight: '1rem'}} onClick={() => editbookmarkdata(data)}><Edit2 /></a></li>
                                                                <li className="pull-right text-end"><a style={{color: 'red'}} href={`#${data.id}`} onClick={() => Removefrombookmark(data)} ><Trash2 /></a></li>
                                                                { isDeleting === data.id && <li>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                </li>
                                                                } {" "}
                                                            </ul>
                                                            <Modal isOpen={editModal} toggle={editToggle} size="lg">
                                                                <ModalHeader toggle={editToggle}>{"Edit " + editRow?.name ?? ""}</ModalHeader>
                                                                <ModalBody>
                                                                <Form action='#' className="form-bookmark needs-validation" onSubmit={(e) => handleEdit(e)}>
                                                                    <div className="form-row">
                                                                        <FormGroup className="col-md-12">
                                                                            <Label>{"Category name"}</Label>
                                                                            <input defaultValue={editRow?.name ?? ""} className="form-control" name="name" type="text" autoComplete="off" {...register('name',{ required: true })} />
                                                                            <span style={{ color: "red" }}>{errors.name && 'Name is required'}</span>
                                                                        </FormGroup>
                                                                        <FormGroup className="col-md-12">
                                                                            <Label>{"Category name En"}</Label>
                                                                            <input defaultValue={editRow?.name_fr ?? ""} className="form-control" name="name_fr" type="text" autoComplete="off" {...register('name_fr',{ required: true })} />
                                                                            <span style={{ color: "red" }}>{errors.name_fr && 'Name Fr is required'}</span>
                                                                        </FormGroup>
                                                                        <FormGroup className="col-md-12">
                                                                            <Label>{"Category name Fr"}</Label>
                                                                            <input defaultValue={editRow?.name_en ?? ""} className="form-control" name="name_en" type="text" autoComplete="off" {...register('name_en',{ required: true })} />
                                                                            <span style={{ color: "red" }}>{errors.name_en && 'Name En is required'}</span>
                                                                        </FormGroup>
                                                                    </div>
                                                                    <Button 
                                                                        color="secondary" 
                                                                        type='button' 
                                                                        onClick={handleEdit}
                                                                        className="me-1">
                                                                        { isEditting && <Spinner
                                                                            as="span"
                                                                            animation="grow"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />} {" "}
                                                                        {"Edit"}
                                                                    </Button>
                                                                    <Button color="primary" onClick={editToggle}>{"Cancel"}</Button>
                                                                </Form>
                                                                </ModalBody>
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default JobCategories;