

export const AUTHURLS = {
    login: "auth/login/",
    refresh_token: "auth/token/refresh/",
    userInfo: (userId) => `users/${userId}/`,
}


export const STOREURLS = {
    ARTICLES: {
        list: "store/v1/articles/",
        create: "store/v1/articles/",
        addImage: (id) => `store/v1/articles/${id}/add_image/`,
    },
    CATEGORIES: {
        list: "store/v1/category/",
        create: "store/v1/category/",
        details: (id) => `store/v1/category/${id}/`,
    },
    IMAGES: {
        list: "store/v1/images/",
        create: "store/v1/images/",
        details: (id) => `store/v1/images/${id}/`,
    },
}