import { AUTHAPI } from "./auth";
import { AUTHURLS } from "./urls";


export const refreshToken = async () => {
  let refresh_token = localStorage.getItem('refresh_token');
  console.log("access_[token", refresh_token);
  const newToken = await AUTHAPI.post(AUTHURLS.refresh_token, {
    refresh_token: refresh_token,
  })
    .then((resp) => {
      const data = resp.data;
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      console.log("access_token", resp.data.access_token);
      return resp.data.access_token;
    })
    .catch(() => {
      window.location.pathname = '/login';
    });
  return newToken;
};
