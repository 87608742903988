import { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Link } from "react-router-dom";
import { API } from "../../api/auth/auth";
import { STOREURLS } from "../../api/auth/urls";
import { Edit, Trash } from "react-feather";

const ArticleCategories = () => {

    const [categories, setCategories] = useState();

    useEffect(() => {
        API.get(STOREURLS.CATEGORIES.list).then((resp) => {
            const {data} = resp;
            setCategories(data)
        })
    }, []);
  return (
    <Fragment>
      <Breadcrumb title="Ctégories" parent="Store" />
      <div className="container-fluid search-page">
        <div className="d-flex">
          <div className="ml-auto">
            <Link to="/store/categories/new" className="btn btn-primary">Nouvelle catégorie</Link>
          </div>
        </div>
        <div className="mt-4">
          <div className="card p-4">
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Titre</th>
                  <th scope="col">Description</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
              {
                  categories?.results.map((category, index) => (
                    <tr key={category.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{category.title}</td>
                      <td>{String(category?.description).slice(0, 100)}</td>
                      <td>
                        <Link to="#" className="btn p-0 mr-2" style={{marginRight: "1rem"}}>
                          <Edit fontSize={"12px"} size={"12px"} />
                        </Link>
                        <Link to="#" className="btn p-0 mr-2">
                          <Trash fontSize={"12px"} size={"12px"} />
                        </Link>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ArticleCategories;
