

const uniqByKeepLast = (a, key) => {
    return [
        ...new Map(
            a.map(x => [key(x), x])
        ).values()
    ]
}


export { uniqByKeepLast }