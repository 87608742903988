import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import JobFilter from './job-filter';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { RECRUITMENTAPI } from '../../api/axios';
import { toast } from 'react-toastify';
import { Skeleton } from '@mui/material';

const CardView = () => {
    const navigate = useNavigate();
    const clickApply = (job) => {
        navigate(`${process.env.PUBLIC_URL}/jobSearch/job-detail/${job.id}`);
    }

    const [JobData, setJobData] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.PUBLIC_URL}/api/jobSearch.json`).then(res => setJobData(res.data))

        RECRUITMENTAPI.get("jobs/").then((resp) => {
            // console.log(resp)
            setJobs(resp.data.results);
            setIsLoading(false);
        }).catch(err => {
            setTimeout(() => {
                toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
            }, 200);
            setIsLoading(false);
        });

    }, [])

    return (
        <Fragment>
            <Breadcrumb title="Jobs list" parent="Jobs" />
            <div className="container-fluid">
                <div className="row">
                    <JobFilter />
                    <div className="col-xl-9 xl-60">
                        <div className="row">
                            {
                                isLoading && [1, 2, 3, 4, 5, 6].map((item) => {


                                    return(
                                        <div className="col-xl-6 xl-100 mb-2" key={item}>
                                            <div className={`card card `}>
                                                <div className="job-search">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-start">
                                                            <div className='m-r-20'>
                                                                <Skeleton width={60} variant="rounded" height={60} />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h6 className="f-w-600">
                                                                    <Skeleton variant="rounded" /> <br/>
                                                                    <Skeleton variant="rounded" />
                                                                    {/* {(data.badgeType === 'primary' ?
                                                                        <span className="badge badge-primary pull-right">
                                                                            {data.badgeValue}
                                                                        </span>
                                                                        : <span className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary">
                                                                            <i className="icofont icofont-love">{data.badgeValue}</i>
                                                                        </span>
                                                                    )} */}
                                                                </h6>
                                                                <p>
                                                                    <Skeleton variant="rounded" height={60} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='card-desc'>
                                                            <div className='card-desc' >
                                                                <Skeleton variant="rounded" /> <br/>
                                                                <Skeleton variant="rounded" /> <br/>
                                                                <Skeleton variant="rounded" /> <br/>
                                                                <Skeleton variant="rounded" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {!isLoading && jobs.map((data, i) => {
                                return (
                                    <div className="col-xl-6 xl-100" key={data.id}>
                                        <div className={`card card `}>
                                            <div className="job-search">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-start">
                                                        <img className="img-40 img-fluid m-r-20" src={`${data.file ?? ""}`} alt={data.job_title} />
                                                        <div className="flex-grow-1">
                                                            <h6 className="f-w-600">
                                                                <a onClick={() => clickApply(data)} href="#">
                                                                    {data.job_title}
                                                                </a>
                                                                {/* {(data.badgeType === 'primary' ?
                                                                    <span className="badge badge-primary pull-right">
                                                                        {data.badgeValue}
                                                                    </span>
                                                                    : <span className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary">
                                                                        <i className="icofont icofont-love">{data.badgeValue}</i>
                                                                    </span>
                                                                )} */}
                                                            </h6>
                                                            <p>
                                                                {data.job_location ?? ""}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='card-desc'>
                                                        <div className='card-desc' dangerouslySetInnerHTML={{__html: data.job_description ?? ""}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CardView;