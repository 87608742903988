import { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Formik } from "formik";
import { API } from "../../api/auth/auth";
import { STOREURLS } from "../../api/auth/urls";
import { toast } from "react-toastify";
import { Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const NewArticle = () => {
  const [categories, setCategories] = useState([]);
  const [mainImage, setMainImage] = useState();
  const [images, setImages] = useState();

  const handleChangeMainImage = (event) => {
    setMainImage(event.target.files[0]);
  }
  const handleChangeImages = (event) => {
    setImages(event.target.files);
  }

  const navigate = useNavigate()


  useEffect(() => {
    API.get(STOREURLS.CATEGORIES.list).then((resp) => {
      setCategories(resp.data.results);
    });
  }, []);
  return (
    <Fragment>
      <Breadcrumb title="Nouvel article" parent="Articles" />
      <div className="container-fluid search-page">
        <div className="mt-4">
          <div>
            <div className="card">
              <div className="p-4">
                <Formik
                  initialValues={{
                    title: "",
                    category: "",
                    description: "",
                    ratings: "",
                    ratings_count: "",
                    price: "",
                    store: "",
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    const formData = new FormData();
                    formData.append("title", values.title);
                    formData.append("category", values.category);
                    formData.append("ratings", values.ratings);
                    formData.append("ratings_count", values.ratings_count);
                    formData.append("price", values.price);
                    formData.append("store", values.store);
                    if(mainImage) {
                      formData.append("main_image", mainImage);
                    }
                    setSubmitting(false);
                    API.post(STOREURLS.ARTICLES.create, formData).then((resp) => {
                      const { data } = resp
                      if(images){
                        for (let index = 0; index < images.length; index++) {
                          const image = images[index];
                          let formData = new FormData()
                          formData.append("image", image);
                          API.put(STOREURLS.ARTICLES.addImage(data.id), formData)
                          
                        }
                        navigate("/store/articles");
                      } else {
                        navigate("/store/articles");
                      }
                      toast.success("Article successfull created");
                    }).catch((error) => {});
                    
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form className="theme-form" onSubmit={handleSubmit}>
                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">{"Titre"}</label>
                            <input
                              className="form-control"
                              type="text"
                              id="title"
                              placeholder="Microsoft Office 2013"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">{"Prix"}</label>
                            <input
                              className="form-control"
                              id="price"
                              type="text"
                              placeholder="10 000"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.price}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              {"Boutique"}
                            </label>
                            <select
                              className="form-control"
                              type="text"
                              name="store"
                              placeholder="Microsoft Office 2013"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.store}
                            >
                              <option value="LICENSES">Licenses</option>
                              <option value="EQUIPMENTS">Equipements</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label w-100">
                              {"Categorie"}
                            </label>
                            <select
                              name="category"
                              id="category"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.category}
                              className="form-control  w-100 "
                            >
                              {categories.map((cat) => (
                                <option value={cat.id}>{cat.title}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              {"Moyenne"}
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              id="ratings"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.ratings}
                              required
                              placeholder="4.3"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              {"Nombre de votes"}
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.ratings_count}
                              required
                              id="ratings_count"
                              placeholder="234"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              {"Image d'affiche"}
                            </label>
                            <input
                              className="form-control"
                              accept="image/*"
                              required
                              type="file"
                              onChange={handleChangeMainImage}
                              placeholder="Microsoft Office 2013"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              {"Autres images"}
                            </label>
                            <input
                              className="form-control"
                              accept="image/*"
                              multiple
                              onChange={handleChangeImages}
                              type="file"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              {"Description"}
                            </label>
                            <textarea
                              name="description"
                              id="description"
                              required
                              cols="30"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                              rows="5"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-12">
                          <div className="form-group">
                            <button className="btn btn-primary" type="submit">
                              Créer l'article{" "}
                              {isSubmitting && <Spinner animation="border" size="sm"/>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewArticle;
