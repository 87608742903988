import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { useNavigate } from 'react-router-dom';
import { RECRUITMENTAPI } from '../../api/axios';
import { toast } from 'react-toastify';
import { CONTACTAPI } from '../../api/contact';
import ContactDetails from './contactsDetails';

import { Eye, Trash } from 'react-feather';
import SweetAlert from 'sweetalert2';
import { Spinner, Table } from 'reactstrap';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const Contacts = () => {
    const navigate = useNavigate();
    const clickApply = (job) => {
        navigate(`${process.env.PUBLIC_URL}/jobSearch/job-detail/${job.id}`);
    }

    const [JobData, setJobData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [contacts, setContacts] = useState([])

    const [isOpen, setIsOpen] = useState(false);
    const [toDelete, setToDelete] = useState([]);
    const [contact, setContact] = useState(undefined);

    const handleModalClose = () => {
        setIsOpen(!isOpen)
    }

    const handleShowDetails = (event, contact) => {
        event.preventDefault();
        setIsOpen(true);
        setContact(contact);
    }

    const handleDeleteSubscription = (event, subsc) => {
        event.preventDefault();
        setContact(subsc);
        
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this Training subscription!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                // setIsDeleting(subsc.id);
                setToDelete(subsc.id);
                CONTACTAPI.delete(`contact/${subsc.id}/`).then((resp) => {
                
                    setTimeout(() => {
                        toast.success("La souscription a bien ete supprimee");
                    }, 200);
                    const newArr = toDelete.filter(item => item != subsc.id);
                    setToDelete(newArr);
                    
                    // Remove deleted idtem from array

                    const newSubscriptionsArray = contacts.filter(item => item.id != subsc.id);
                    setContacts(newSubscriptionsArray);
                    // navigate(`${process.env.PUBLIC_URL}/jobSearch/jobs`);
                    SweetAlert.fire(
                    'Deleted!',
                    'The Subscription has been deleted.',
                    'success'
                    )
                }).catch(err => {
                    setTimeout(() => {
                        toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
                    }, 200);
                    // setIsDeleting(false);
                    const newArr = toDelete.filter(item => item != subsc.id);
                    setToDelete(newArr);
                })
            }
            else {
                SweetAlert.fire(
                'Your Subscription is safe!'
                )
            }
        })
    }

    useEffect(() => {
        RECRUITMENTAPI.get(`jobs/`).then(res => setJobData(res.data.results))

        CONTACTAPI.get("contact/").then((resp) => {
            // console.log(resp)
            // fetch job applications 
            
            let results = resp.data.results;
            
            setContacts(results);
            setIsLoading(false);
        }).catch(err => {
            setTimeout(() => {
                toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
            }, 200);

            setIsLoading(false);
        });
    }, [])

    return (
        <Fragment>
            <Breadcrumb title="Contact messages list" parent="Contact" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-60">
                        <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>{"Contact messages"}</h5>
                                </div>
                                <div className="card-block row">
                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                        <div className="table-responsive">

                                            {
                                                isLoading && <Box sx={{ width: '100%', marginBottom: '1rem' }}>
                                                    <LinearProgress />
                                                </Box>
                                            }
                                            <table className="table table-hover">
                                                <caption>{"List of contact messages"}</caption>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{"#"}</th>
                                                        <th scope="col">{"First Name"}</th>
                                                        <th scope="col">{"Last Name"}</th>
                                                        <th scope="col">{"Email"}</th>
                                                        <th scope="col">{"Phone"}</th>
                                                        <th scope="col">{"Subject"}</th>
                                                        <th scope="col">{"Message"}</th>
                                                        <th scope="col">{"Actions"}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        !isLoading && contacts.length===0 && <p className="text-center">{"Any data"}</p>
                                                    }
                                                    {
                                                        !isLoading && contacts.map((contact, index) => {

                                                            return(
                                                                <tr key={`application-${contact.id}`}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>
                                                                        <p className="text-1-line">
                                                                        {contact.first_name}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="text-1-line">
                                                                        {contact.last_name}
                                                                        </p>    
                                                                    </td>
                                                                    <td>
                                                                        <p className="text-1-line">
                                                                        {contact.email}
                                                                        </p> 
                                                                    </td>
                                                                    <td>
                                                                        <p className="text-1-line">
                                                                        {contact.phone}
                                                                        </p> 
                                                                    </td>
                                                                    <td>
                                                                        <p className="text-1-line">
                                                                        {contact.subject}
                                                                        </p> 
                                                                    </td>
                                                                    <td>
                                                                        <p className="text-1-line">
                                                                            {contact.message}
                                                                        </p> 
                                                                    </td>
                                                                    <td>
                                                                        <a href={`#`} onClick={(event) => handleShowDetails(event, contact)} ><Eye/></a>
                                                                        {' '}
                                                                        {/* <a href='#'><i data-feather="eye"></i></a>
                                                                        <a href='#'><i data-feather="eye"></i></a> */}
                                                                        { !toDelete.includes(contact.id) ? <a href={`#`} onClick={(event) => handleDeleteSubscription(event, contact)}><Trash color='red'/></a> :
                                                                        <Spinner style={{color: 'red'}} size={10}/>}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <ContactDetails isOpen={isOpen} modalToggle={handleModalClose} subscription={contact} />
            </div>
        </Fragment>
    );
};

export default Contacts;