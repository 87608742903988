import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RECRUITMENTAPI } from '../../../api/axios';
import { toast } from 'react-toastify';
import { Spinner, Table } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { Container, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, TabContent, TabPane, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Button, Input } from 'reactstrap'
import { Check, X } from 'react-feather';



function OrderedTrainingDetails (props){
    const pathname = window.location.pathname;
    const pathId = pathname.substring(pathname.lastIndexOf('/') + 1);
    const params = useParams()
    const [singleData, setSingleData] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    const {modalToggle, isOpen, subscription} = props;
    

    const navigate = useNavigate();
    const clickApply = (job) => {
      navigate(`${process.env.PUBLIC_URL}/jobSearch/job/edit/${job.id}`);
    }

    // useEffect(() => {
    //     axios.get(`${process.env.PUBLIC_URL}/api/jobSearch.json`).then(res => {
    //         setJobData(res.data)
    //         res.data.filter((data) => 
    //         {
    //             if (data.Id == id) {
    //                return setSingleData(data)
    //             }
    //             return 0
    //         })
    //     })
    // }, [id]);

    const Removefrombookmark = (data) => {
        SweetAlert.fire({
          title: 'Are you sure?',
          text: "Once deleted, you will not be able to recover this job offer!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          cancelButtonText: 'cancel',
          reverseButtons: true
        }).then((result) => {
            if (result.value) {
                setIsDeleting(data.id);
                RECRUITMENTAPI.delete(`jobs/${data.id}/`).then((resp) => {
               
                    setTimeout(() => {
                        toast.success("L'offre a bien ete supprimee");
                    }, 200);
                    setIsDeleting(false);

                    navigate(`${process.env.PUBLIC_URL}/jobSearch/jobs`);

                }).catch(err => {
                    setTimeout(() => {
                        toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
                    }, 200);
                    setIsDeleting(false);
                })
                SweetAlert.fire(
                'Deleted!',
                'The job has been deleted.',
                'success'
                )
            }
            else {
                SweetAlert.fire(
                'Your job is safe!'
                )
            }
        })
    }

    useEffect(() => {
        // console.log("location===>", params)
        // RECRUITMENTAPI.get(`jobs/${params.id}/`).then(res => {
        //     setSingleData(res.data)
            
        // }).catch(err => {
        //     setTimeout(() => {
        //         toast.error("Oppss.. Une erreur s'est produite. Cet offre d'emploi n'existe peut-etre pas.");
        //     }, 200);
        // });
    }, [])


    return (
        <Fragment>
            <Modal isOpen={isOpen} toggle={modalToggle} size="lg">
              <ModalHeader toggle={modalToggle}>{"Details"}</ModalHeader>
              <ModalBody>
                <div>
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <td>
                                {"First Name"}
                                </td>
                                <td>
                                {subscription?.first_name}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Last Name"}
                                </td>
                                <td>
                                {subscription?.last_name}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Email"}
                                </td>
                                <td>
                                {subscription?.email}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Phone"}
                                </td>
                                <td>
                                {subscription?.phone}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Postal code"}
                                </td>
                                <td>
                                {subscription?.postal_code}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Training"}
                                </td>
                                <td>
                                {subscription?.training}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Society"}
                                </td>
                                <td>
                                {subscription?.society}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Role"}
                                </td>
                                <td>
                                {subscription?.role}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Number of inscriptions"}
                                </td>
                                <td>
                                {subscription?.number_of_inscriptions}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Interested by the course"}
                                </td>
                                <td>
                                {subscription?.interested_by_the_course ? <Check size={15} style={{color: 'green'}}/> : <X size={15} style={{color: 'red'}}/> }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Other informations"}
                                </td>
                                <td>
                                {subscription?.oher_informations}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
              </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default OrderedTrainingDetails;