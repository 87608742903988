import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner, Table } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { Container, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, TabContent, TabPane, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Button, Input } from 'reactstrap'
import { Check, X } from 'react-feather';



function ContactDetails (props){
    const pathname = window.location.pathname;
    const pathId = pathname.substring(pathname.lastIndexOf('/') + 1);
    const params = useParams()
    const [singleData, setSingleData] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    const {modalToggle, isOpen, subscription} = props;
    

    const navigate = useNavigate();
    const clickApply = (job) => {
      navigate(`${process.env.PUBLIC_URL}/jobSearch/job/edit/${job.id}`);
    }

    // useEffect(() => {
    //     axios.get(`${process.env.PUBLIC_URL}/api/jobSearch.json`).then(res => {
    //         setJobData(res.data)
    //         res.data.filter((data) => 
    //         {
    //             if (data.Id == id) {
    //                return setSingleData(data)
    //             }
    //             return 0
    //         })
    //     })
    // }, [id]);

    useEffect(() => {
        // console.log("location===>", params)
        // RECRUITMENTAPI.get(`jobs/${params.id}/`).then(res => {
        //     setSingleData(res.data)
            
        // }).catch(err => {
        //     setTimeout(() => {
        //         toast.error("Oppss.. Une erreur s'est produite. Cet offre d'emploi n'existe peut-etre pas.");
        //     }, 200);
        // });
    }, [])


    return (
        <Fragment>
            <Modal isOpen={isOpen} toggle={modalToggle} size="lg">
              <ModalHeader toggle={modalToggle}>{"Details"}</ModalHeader>
              <ModalBody>
                <div>
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <td>
                                {"Full Name"}
                                </td>
                                <td>
                                {subscription?.name ?? subscription?.first_name + " " + subscription?.last_name}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Email"}
                                </td>
                                <td>
                                {subscription?.email}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Phone"}
                                </td>
                                <td>
                                {subscription?.phone}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Subject"}
                                </td>
                                <td>
                                {subscription?.custom_subject ?? subscription?.subject}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"File"}
                                </td>
                                <td>
                                    <p><a href={subscription?.file ?? "#"}></a></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {"Message"}
                                </td>
                                <td>
                                {subscription?.message}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
              </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default ContactDetails;