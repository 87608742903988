import React, { Fragment, useState ,useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import JobFilter from './job-filter';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker from "react-datepicker";
import CKEditors from "react-ckeditor-component";
import {Submit} from "../../constant";
import axios from 'axios'
import { Formik, Form, Field } from 'formik';
import { RECRUITMENTAPI } from '../../api/axios';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const EditJob = () => {

    const params = useParams()
    const pathname = window.location.pathname;
    const pathId = pathname.substring(pathname.lastIndexOf('/') + 1);
    const id = pathId ? pathId :1
    const [job, setJob] = useState(null);
    const [startDate, setStartDate] = useState(job?.job_starting_date);
    const [startDate1, setStartDate1] = useState(job?.job_application_delay);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [singleData, setSingleData] = useState('');


    const [isSubmiting, setIsSubmiting] = useState(false);

    const [file, setFile] = useState(null);

    const [jobType, setJobType] = useState('');
    const [jobDisponibility, setJobDisponibility] = useState('');

    const [jobDescription, setJobDescription] = useState(job?.job_description) 
    const [jobDescriptionFr, setJobDescriptionFr] = useState(job?.job_description_fr) 
    const [jobDescriptionEn, setJobDescriptionEn] = useState(job?.job_description_en) 

    const [jobCompetences, setJobCompetences] = useState(job?.job_competences_experience) 
    const [jobResponsabilities, setJobResponsabilities] = useState(job?.job_responsabilities) 
    // const [jobDescriptionEn, setJobDescriptionEn] = useState('Job description en') 
    
    const onChangeJobDescription = (evt) => {
        const newContent = evt.editor.getData();
        setJobDescription(newContent)
    }

    const onChangeJobDescriptionFr = (evt) => {
        const newContent = evt.editor.getData();
        setJobDescriptionFr(newContent)
    }

    const onChangeJobDescriptionEn = (evt) => {
        const newContent = evt.editor.getData();
        setJobDescriptionEn(newContent)
    }


    useEffect(() => {

        // Fetch jobs categories
        RECRUITMENTAPI.get("categories/").then(resp => {
            setCategories(resp.data.results);

        }).catch(err => console.log(err) );

    }, [id]);

    
    const handleChange = date => {
       setStartDate(date)
    };

    const handleChange1 = date => {
        setStartDate1(date);
    };

    const initialValues = {
        jobTitle: job?.job_title ?? "",
        jobTitleEn: job?.job_title_en ?? "",
        jobTitleFr: job?.job_title_fr ?? "",
        jobDuration: job?.job_duration_months ?? 0,
        jobLocation: job?.job_location ?? "",
    }

    const handleSubmit = (values) => {
        setIsSubmiting(true);
        const data = new FormData();

        data.append("job_title", values.jobTitle);
        data.append("job_title_en", values.jobTitleEn);
        data.append("job_title_fr", values.jobTitleFr);
        data.append("job_duration_months", values.jobDuration);

        data.append("job_application_delay", moment(startDate1).format().slice(0, 10));
        data.append("job_starting_date", moment(startDate).format().slice(0, 10));
        data.append("job_type", jobType);

        data.append("job_disponibility", jobDisponibility);
        data.append("job_responsabilities", jobResponsabilities);
        data.append("job_competences_experience", jobCompetences);
        
        data.append("job_description", jobDescription);
        data.append("job_description_en", jobDescriptionEn);
        data.append("job_description_fr", jobDescriptionFr);

        data.append("job_location", values.jobLocation);

        if (file) {

            data.append("file", file);
        }

        console.log("selectedCategory =======> ", selectedCategory)

        if(selectedCategory && typeof selectedCategory[0] === 'string') {

            data.append("job_category", selectedCategory[0]);
        } else if(selectedCategory && typeof selectedCategory[0] !== 'string') {

            data.append("job_category", selectedCategory[0].id);
        }

        RECRUITMENTAPI.patch(`jobs/${job.id}/`, data).then((resp) => {

            setTimeout(() => {
                toast.success("L'offre d'emploi a bien ete modifiee'");
            }, 200);
            setIsSubmiting(false);
        }).catch(err => {
            console.log(err)
            setTimeout(() => {
                toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
            }, 200);
            setIsSubmiting(false);
        })
    }

    const handleChangeFile = (event) => {

        let file = event.target.files[0];

        setFile(file);
    }

    const initialize = (job) => {

        const date = moment(new Date()).format().slice(10)
        setStartDate(job?.job_starting_date);
        setStartDate1(job?.job_application_delay);

        setJobDescription(job?.job_description);
        setJobDescriptionEn(job?.job_description_en);
        setJobDescriptionFr(job?.job_description_fr);

        setJobCompetences(job?.job_competences_experience);
        setJobResponsabilities(job?.job_responsabilities);

        setJobDisponibility(job?.job_disponibility);

        setJobType(job?.job_type);

        if(job?.job_category){

            setSelectedCategory([].concat(job?.job_category));
        }

        // console.log("lkldkokld====>", job?.job_starting_date + date)
        setJob(job);
    }

    useEffect(() => {
        // console.log("location===>", params)
        RECRUITMENTAPI.get(`jobs/${params.id}/`).then(resp => {
            
            initialize(resp.data);
            
        }).catch(err => {
            setTimeout(() => {
                toast.error("Oppss.. Une erreur s'est produite. Cet offre d'emploi n'existe peut-etre pas.");
            }, 200);
        })
    }, [])

    return (
        <Fragment>
            <Breadcrumb title="Edit job" parent="Jobs" />
            {
                job && 
                <div className="container-fluid">
                    <div className="row">
                        {/* <JobFilter /> */}
                        <div className="col-xl-9 xl-60">
                            <div className="card">
                                <div className="job-search">
                                    <div className="card-body pb-0">
                                        {/* <div className="d-flex align-items-start">
                                            <img className="img-40 img-fluid m-r-20" src={one} alt="" />
                                            <div className="flex-grow-1">
                                                <h6 className="f-w-600">
                                                    <a href="#javascript">{singleData.job_name}</a>
                                                    <span className="pull-right">
                                                        <button className="btn btn-primary" type="button"><span><i className="fa fa-check text-white"></i></span> {"Save this job"}</button></span></h6>
                                                    <p>{singleData.job_area} && {singleData.job_city}<span>
                                                        <i className="fa fa-star font-warning"></i>
                                                        <i className="fa fa-star font-warning"></i>
                                                        <i className="fa fa-star font-warning"></i>
                                                        <i className="fa fa-star font-warning"></i>
                                                        <i className="fa fa-star font-warning"></i>
                                                        </span>
                                                    </p>
                                            </div>
                                        </div> */}
                                        <div className="job-description">
                                            <h6 className="mb-0">{"Job informations"} </h6>
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={(values) => handleSubmit(values)}
                                        >
                                            { 
                                                ({resetForm}) => (
                                                    <Form className="form theme-form">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobTitle">{"Job Title"}:<span className="font-danger">{"*"}</span></label>
                                                                    <Field className="form-control" id="jobTitle" name="jobTitle" type="text" placeholder="Enter the job title" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobTitleFr">{"Job Title fr"}:<span className="font-danger">{"*"}</span></label>
                                                                    <Field className="form-control" id="jobTitleFr" name="jobTitleFr" type="text" placeholder="Enter the job title in french" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobTitleEn">{"Job Title en"}:<span className="font-danger">{"*"}</span></label>
                                                                    <Field className="form-control" id="jobTitleEn" name="jobTitleEn" type="text" placeholder="Enter the job title in english" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDescription">{"Job Description"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobDescription}
                                                                        events={{
                                                                            "change": onChangeJobDescription
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDescriptionFr">{"Job Description fr"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobDescriptionFr}
                                                                        events={{
                                                                            "change": onChangeJobDescriptionFr
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDescriptionEn">{"Job Description En"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobDescriptionEn}
                                                                        events={{
                                                                            "change": onChangeJobDescriptionEn
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDuration">{"Job duration in months"}:</label>
                                                                    <Field className="form-control" name="jobDuration" id="jobDuration" type="number" placeholder=" 3 " />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobLocation">{"Job location"}:</label>
                                                                    <Field className="form-control" id="jobLocation" name="jobLocation" type="text" placeholder=" Akwa, Douala Cameroun " />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobStartingDate">{"Job Starting date"}:</label>
                                                                    <input
                                                                        type={"date"} 
                                                                        className="form-control digits" 
                                                                        value={startDate} 
                                                                        onChange={(event) => handleChange(event.target.value)} 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobApplicationDelay">{"Job application delay"}:</label>
                                                                    <input 
                                                                        type={"date"}
                                                                        value={startDate1} 
                                                                        className="form-control digits" 
                                                                        onChange={(event) => handleChange1(event.target.value)} 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobType">{"Job type"}:</label>
                                                                    <Typeahead
                                                                        defaultInputValue={jobType}
                                                                        id="jobType"
                                                                        labelKey="name"
                                                                        multiple={false}
                                                                        onChange={(selected) => setJobType(selected)}
                                                                        options={['CDI', 'CDD', 'ACADEMIC_STAGE', 'PROFESSIONAL_STAGE']}
                                                                        placeholder="Job type"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDisponibility">{"Job disponibility"}:</label>
                                                                    <Typeahead
                                                                        defaultInputValue={jobDisponibility}
                                                                        id="jobDisponibility"
                                                                        labelKey="name1"
                                                                        onChange={(selected) => setJobDisponibility(selected) }
                                                                        multiple={false}
                                                                        options={['FULLTIME', 'PARTIALTIME']}
                                                                        placeholder="Job disponibility"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobCategory">{"Job type"}:</label>
                                                                    <Typeahead
                                                                        id="jobDisponibility"
                                                                        labelKey="name2"
                                                                        multiple={false}
                                                                        options={['FULLTIME', 'PARTIALTIME']}
                                                                        placeholder="Marketing"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobCategory">{"Job category"}:</label>
                                                                    <Typeahead
                                                                        id="jobCategory"
                                                                        defaultInputValue={ categories.filter(item => item.id === selectedCategory)[0] ?? ""}
                                                                        labelKey="name"
                                                                        multiple={false}
                                                                        options={categories}
                                                                        onChange={(selected) => {setSelectedCategory(selected)} }
                                                                        placeholder="Marketing"
                                                                        renderMenuItemChildren={(option, props) => (
                                                                            <Fragment>
                                                                                <span>{option.name}</span>
                                                                            </Fragment>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobImage">{"Job Image"}:</label>
                                                                    <input className="form-control" id="jobImage" type="file" onChange={handleChangeFile}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <img height={"300px"} src={job?.file} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer">
                                                            <button className="btn btn-primary me-1" type="submit">
                                                                { isSubmiting && 
                                                                < Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            
                                                            } {" "}
                                                                {Submit}</button>
                                                            <Field className="btn btn-light" type="reset" value="Cancel" />
                                                        </div>
                                                    </Form>
                                                )
                                            }
                                        </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
                    
        </Fragment>
    );
};

export default EditJob;