import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RECRUITMENTAPI } from '../../api/axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { CONTACTAPI } from '../../api/contact';



const ContactDetail = () => {
    const pathname = window.location.pathname;
    const pathId = pathname.substring(pathname.lastIndexOf('/') + 1);
    const params = useParams()
    const [singleData, setSingleData] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [job, setJob] = useState(null);
    

    const navigate = useNavigate();
    const clickApply = (job) => {
        navigate(`${process.env.PUBLIC_URL}/jobSearch/job/edit/${job.id}`);
    }

    useEffect(() => {
        CONTACTAPI.get(`contact/${params.id}/`).then(res => {
            setSingleData(res.data);
            
        }).catch(err => {
            setTimeout(() => {
                toast.error("Oppss.. Une erreur s'est produite. Cet offre d'emploi n'existe peut-etre pas.");
            }, 200);
        })
    }, [])


    return (
        <Fragment>
            <Breadcrumb title="Contact details" parent="Contact" />
            <div className="container-fluid">
                <div className="row">
                    {/* <JobFilter /> */}
                    <div className="col-xl-9 xl-60">
                        <div className="card">
                            <div className="job-search">
                                 <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        {/* <img className="img-40 img-fluid m-r-20" src={singleData.file} alt={singleData.job_title} /> */}
                                        <div className="flex-grow-1">
                                            <h6 className="f-w-600">
                                                <a href="#">{singleData.first_name + " " + singleData.last_name}</a>
                                                {/* <span className="pull-right">
                                                    <button className="btn btn-primary" type="button" onClick={() => jobApply(singleData)}>{"Apply for this job"}</button>
                                                </span> */}
                                                {/* <div className='pull-right'>
                                                    <ul className="d-flex">
                                                        <li><a href={`#${singleData.id}`} style={{marginRight: '1rem'}} onClick={() => clickApply(singleData)}><Edit2 /></a></li>
                                                        <li className="pull-right text-end"><a style={{color: 'red'}} href={`#${singleData.id}`} onClick={() => Removefrombookmark(singleData)} ><Trash2 /></a></li>
                                                        { isDeleting === singleData.id && <li>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </li>
                                                        } {" "}
                                                    </ul>
                                                </div> */}
                                            </h6>
                                            <p>{moment(singleData.created_at).format('l')}
                                                {/* <span>
                                                    <i className="fa fa-star font-warning"></i>
                                                    <i className="fa fa-star font-warning"></i>
                                                    <i className="fa fa-star font-warning"></i>
                                                    <i className="fa fa-star font-warning"></i>
                                                    <i className="fa fa-star font-warning"></i>
                                                </span> */}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-description">
                                        <h6>{"Email:"}</h6>
                                        <p>{singleData.email}</p>
                                    </div>
                                    <div className="job-description">
                                        <h6>{"Phone:"} </h6>
                                        <div>
                                            {
                                                singleData.phone
                                            }
                                        </div>
                                    </div>
                                    <div className="job-description">
                                        <h6>{"Subject:"}</h6>
                                        <div>
                                            {singleData?.subject}
                                        </div>
                                    </div>
                                    <div className="job-description">
                                        <h6>{"Message:"}</h6>
                                        <div>
                                            {singleData?.message}
                                        </div>
                                    </div>
                                    
                                    <div className="job-description">
                                        <h6>{"Joined paper:"}</h6>
                                        <div>
                                            <iframe src={singleData.file} height={"600px"} width={"100%"} ></iframe>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="job-description">
                                        <button className="btn btn-primary me-1" type="button"><span><i className="fa fa-check"></i></span> {"Save this job"}</button>
                                        <button className="btn btn-primary" type="button"><span><i className="fa fa-share-alt"></i></span> {Share}</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="header-faq">
                            {/* <h6 className="mb-0 f-w-600">{SimilarJobs}</h6> */}
                        </div>
                        {/* <div className="row">
                            {JobData.slice(0, 4).map((data, i) => {
                                return (
                                    <div className="col-xl-6 xl-100" key={i}>
                                        <div className="card">
                                            <div className="job-search">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center">
                                                        <img className="img-40 img-fluid m-r-20" src={require(`../../assets/images/${data.logo}`)} alt="" />
                                                        <div className="flex-grow-1">
                                                            <h6 className="f-w-600"><a href="#javascript">{data.job_name}</a>
                                                                {(data.badgeType === 'primary' ? <span className="badge badge-primary pull-right">{data.badgeValue}</span>
                                                                    : ''Removefrombookmark
                                                                )}
                                                            </h6>
                                                            <p>{data.job_area} <span>{data.job_city}</span>
                                                                <span>
                                                                    <i className="fa fa-star font-warning"></i>
                                                                    <i className="fa fa-star font-warning"></i>
                                                                    <i className="fa fa-star font-warning"></i>
                                                                    <i className="fa fa-star font-warning"></i>
                                                                    <i className="fa fa-star font-warning-o"></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <p>{data.Job_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="job-search">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <img className="img-40 img-fluid m-r-20" src={two} alt="" />
                                                <div className="flex-grow-1">
                                                    <h6 className="f-w-600"><a href="#javascript">{SeniorUXDesigner}</a><span className="pull-right">{"5 days ago"}</span></h6>
                                                    <p>{"Sutherland"} <span>{"Lelystad, Netherlands"} </span><span><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning-half-o"></i><i className="fa fa-star font-warning-o"></i></span></p>
                                                </div>
                                            </div>
                                            <p>{"Woody equal ask saw sir weeks aware decay. Entrance prospect removing we packages strictly is no smallest he. For hopes may chief get hours day rooms. Oh no turned behind polite piqued enough at. Forbade few through inquiry blushes you. Cousin no itself eldest it in dinner latter missed no."}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ContactDetail;