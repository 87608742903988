import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { firebase_app } from '../data/config';
import { authHeader, handleResponse } from '../services/fack.backend';

const PrivateRoute = () => {
    
    const [authenticated,setAuthenticated] = useState(false)
    const access_token = localStorage.getItem('access_token');
    const user = localStorage.getItem('user');

    

    useEffect(() => {
        const abortController = new AbortController();
        // const requestOptions = { method: 'GET', headers: authHeader() };
        // fetch('/users', requestOptions).then(handleResponse)
        // firebase_app.auth().onAuthStateChanged(setCurrentUser);
        setAuthenticated(false)
      
        return function cleanup() {
            abortController.abort();
        }
    }, []);

    return (
        user && access_token  ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/login`} />);
}

export default PrivateRoute;

