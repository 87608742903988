import axios from 'axios';
import { refreshToken } from './refresh_token';


// const token = localStorage.getItem('access_token');

// const RECRUITMENTAPI = axios.create({
//     baseURL: '',
//     headers: {
//         "Authorization": token
//     }
//     // timeout: 10000,
// });


// axios.defaults.baseURL = "https://recrutement.ova-consulting.fr/";



// let CONTACTAPI = axios.create({
//   baseURL: 'https://contact.ova-consulting.fr/contact/v1/',
//   // timeout: 10000,
//   headers: {
//     "Authorization": token
//   }
// });

axios.interceptors.request.use(
  async (config) => {
    const session = token;
    const token = localStorage.getItem('access_token');

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `${token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

  // Response interceptor for API calls
  axios.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    console.log("originalRequest ===> ", originalRequest)
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const token = localStorage.getItem('access_token');
      const access_token = await refreshToken();
      console.log(access_token, token)
      // CONTACTAPI.defaults.headers.common['Authorization'] = access_token;
      originalRequest.headers = {
        ...originalRequest.headers,
        "Authorization": `${access_token}`,
      };
      return axios(originalRequest);
    }
    return Promise.reject(error);
  });

const RECRUITMENTAPI = axios.create({
  baseURL: "https://recrutement.ova-consulting.fr/"
})

export { RECRUITMENTAPI };