import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { useNavigate } from 'react-router-dom';
import { RECRUITMENTAPI } from '../../api/axios';
import { toast } from 'react-toastify';
import { Eye } from 'react-feather';
import { uniqByKeepLast } from '../../utils/functions';

const JobApplications = () => {
    const navigate = useNavigate();
    const clickApply = (job) => {
        navigate(`${process.env.PUBLIC_URL}/jobSearch/job-detail/${job.id}`);
    }

    const [JobData, setJobData] = useState([])
    const [applications, setApplications] = useState([])

    useEffect(() => {
        RECRUITMENTAPI.get(`jobs/`).then(res => setJobData(res.data.results))

        RECRUITMENTAPI.get("applications/jobs/?limit=100&offset=0").then((resp) => {
            // console.log(resp)
            // fetch job applications 
            
            let results = resp.data.results;
            const uniqueArray = uniqByKeepLast(results, item => item.first_name);
            // console.log("ffldkfldklkfdl===>", uniqueArray);
            // let copy = resp.data.results;
            
            setApplications(uniqueArray);
        }).catch(err => {
            console.log("ffldkfldklkfdl err ===>", err);
            setTimeout(() => {
                toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
            }, 200);
        });
    }, [])

    return (
        <Fragment>
            <Breadcrumb title="Job applications list" parent="Jobs" />
            <div className="container-fluid">
                <div className="row">
                    {/* <JobFilter /> */}
                    <div className="col-12">
                    {/* <div className="col-xl-9 xl-60"> */}
                        <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>{"Applications"}</h5>
                                </div>
                                <div className="card-block row">
                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <caption>{"List of applications"}</caption>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{"#"}</th>
                                                        <th scope="col">{"First Name"}</th>
                                                        <th scope="col">{"Middle Name"}</th>
                                                        <th scope="col">{"Last Name"}</th>
                                                        <th scope="col">{"Email"}</th>
                                                        <th scope="col">{"Job"}</th>
                                                        <th scope="col">{"Actions"}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        applications.map((application, index) => {

                                                            return(
                                                                <tr key={`application-${application.id}`}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{application.first_name}</td>
                                                                    <td>{application.middle_name}</td>
                                                                    <td>{application.last_name}</td>
                                                                    <td>{application.email}</td>
                                                                    <td>{application.job?.job_title ?? ""}</td>
                                                                    <td>
                                                                        <a href={`${process.env.PUBLIC_URL}/jobSearch/job/details/${application.id}`}><Eye/></a>
                                                                        {/* <a href='#'><i data-feather="eye"></i></a>
                                                                        <a href='#'><i data-feather="eye"></i></a> */}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default JobApplications;