import React, { Fragment, useState ,useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker from "react-datepicker";
import CKEditors from "react-ckeditor-component";
import {Submit} from "../../constant";
import axios from 'axios'
import { Formik, Form, Field } from 'formik';
import { RECRUITMENTAPI } from '../../api/axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalHeader, ModalBody, FormGroup, Label, Button, Input, Spinner } from 'reactstrap'

const NewJob = () => {

    const pathname = window.location.pathname;
    const pathId = pathname.substring(pathname.lastIndexOf('/') + 1);
    const id = pathId ? pathId :1
    const [startDate, setStartDate] = useState(new Date(),);
    const [startDate1, setStartDate1] = useState(new Date(),);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [singleData, setSingleData] = useState('');

    const [isSubmiting, setIsSubmiting] = useState(false);

    const [file, setFile] = useState(null);

    const [jobType, setJobType] = useState('');
    const [jobDisponibility, setJobDisponibility] = useState('');

    const [jobDescription, setJobDescription] = useState('Job description') 
    const [jobDescriptionFr, setJobDescriptionFr] = useState('Job description fr') 
    const [jobDescriptionEn, setJobDescriptionEn] = useState('Job description en') 

    const [jobCompetences, setJobCompetences] = useState('Job competences') 
    const [jobResponsabilities, setJobResponsabilities] = useState('Job responsabilities') 
    // const [jobDescriptionEn, setJobDescriptionEn] = useState('Job description en') 

    const [addSectionModalOpen, setAddSectionModalOpen] = useState(false);


    const addSectionModal = () => { setAddSectionModalOpen(!addSectionModalOpen) };

    
    const onChangeJobDescription = (evt) => {
        const newContent = evt.editor.getData();
        setJobDescription(newContent)
    }

    const onChangeJobDescriptionFr = (evt) => {
        const newContent = evt.editor.getData();
        setJobDescriptionFr(newContent)
    }

    const onChangeJobDescriptionEn = (evt) => {
        const newContent = evt.editor.getData();
        setJobDescriptionEn(newContent)
    }


    useEffect(() => {

        // Fetch jobs categories
        RECRUITMENTAPI.get("categories/").then(resp => {
            setCategories(resp.data.results);

        }).catch(err => console.log(err) )
    }, [id]);

    
    const handleChange = date => {
       setStartDate(date);
    };

    const handleChange1 = date => {
        setStartDate1(date);
    };

    const initialValues = {
        jobTitle: "",
        jobTitleEn: "",
        jobTitleFr: "",
        jobDuration: 0,
        jobLocation: "",
    }

    const navigate = useNavigate();

    const handleSubmit = (values) => {
        setIsSubmiting(true);
        const data = new FormData();

        data.append("job_title", values.jobTitle);
        data.append("job_title_en", values.jobTitleEn);
        data.append("job_title_fr", values.jobTitleFr);
        data.append("job_duration_months", values.jobDuration);

        data.append("job_application_delay", moment(startDate1).format().slice(0, 10));
        data.append("job_starting_date", moment(startDate).format().slice(0, 10));
        data.append("job_type", jobType);

        data.append("job_disponibility", jobDisponibility);
        data.append("job_responsabilities", jobResponsabilities);
        data.append("job_competences_experience", jobCompetences);
        
        data.append("job_description", jobDescription);
        data.append("job_description_en", jobDescriptionEn);
        data.append("job_description_fr", jobDescriptionFr);

        data.append("job_location", values.jobLocation);

        if(selectedCategory) {

            data.append("job_category", selectedCategory[0].id);
        }
        
        if (file) {

            data.append("file", file);
        }

        RECRUITMENTAPI.post(`jobs/`, data).then((resp) => {

            setTimeout(() => {
                toast.success("L'offre d'emploi a bien ete créée");
            }, 200);
            setIsSubmiting(false);
            navigate("/jobSearch/jobs/")
            
        }).catch(err => {
            console.log(err)
            setTimeout(() => {
                toast.error("Oppss.. Une erreur s'est produite. Veuiller reessayer");
            }, 200);
            setIsSubmiting(false);
        })
    }

    const handleChangeFile = (event) => {

        let file = event.target.files[0];

        setFile(file);
    }

    return (
        <Fragment>
            <Breadcrumb title="New job" parent="Jobs" />
                <div className="container-fluid">
                    <div className="row">
                        {/* <JobFilter /> */}
                        <div className="col-xl-9 xl-60">
                            <div className="card">
                                <div className="job-search">
                                    <div className="card-body pb-0">
                                        {/* <div className="d-flex align-items-start">
                                            <img className="img-40 img-fluid m-r-20" src={one} alt="" />
                                            <div className="flex-grow-1">
                                                <h6 className="f-w-600">
                                                    <a href="#javascript">{singleData.job_name}</a>
                                                    <span className="pull-right">
                                                        <button className="btn btn-primary" type="button"><span><i className="fa fa-check text-white"></i></span> {"Save this job"}</button></span></h6>
                                                    <p>{singleData.job_area} && {singleData.job_city}<span>
                                                        <i className="fa fa-star font-warning"></i>
                                                        <i className="fa fa-star font-warning"></i>
                                                        <i className="fa fa-star font-warning"></i>
                                                        <i className="fa fa-star font-warning"></i>
                                                        <i className="fa fa-star font-warning"></i>
                                                        </span>
                                                    </p>
                                            </div>
                                        </div> */}
                                        <div className="job-description">
                                            <h6 className="mb-0">{"Job informations"} </h6>
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={(values) => handleSubmit(values)}
                                        >
                                            { 
                                                ({resetForm}) => (
                                                    <Form className="form theme-form">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobTitle">{"Titre de l'emploi"}:<span className="font-danger">{"*"}</span></label>
                                                                    <Field className="form-control" id="jobTitle" name="jobTitle" type="text" placeholder="Entrer le titre de l'emploi" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobTitleFr">{"Titre de l'emploi en francais"}:<span className="font-danger">{"*"}</span></label>
                                                                    <Field className="form-control" id="jobTitleFr" name="jobTitleFr" type="text" placeholder="Titre de l'emploi en francais" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobTitleEn">{"Titre de l'emploi en anglais"}:<span className="font-danger">{"*"}</span></label>
                                                                    <Field className="form-control" id="jobTitleEn" name="jobTitleEn" type="text" placeholder="Entrer le titre de l'emploi en anglais" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDescription">{"Job Description"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobDescription}
                                                                        events={{
                                                                            "change": onChangeJobDescription
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDescriptionFr">{"Job Description fr"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobDescriptionFr}
                                                                        events={{
                                                                            "change": onChangeJobDescriptionFr
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDescriptionEn">{"Job Description En"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobDescriptionEn}
                                                                        events={{
                                                                            "change": onChangeJobDescriptionEn
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobCompetences">{"Job Competences An experiences"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobCompetences}
                                                                        events={{
                                                                            "change": onChangeJobCompetences
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobResponsabilities">{"Job responsabilities"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobResponsabilities}
                                                                        events={{
                                                                            "change": onChangeJobResponsabilities
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDuration">{"Duree de l'emploi en mois"}:</label>
                                                                    <Field className="form-control" id="jobDuration" name="jobDuration" type="number" placeholder=" 3 " />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobLocation">{"Lieu de travail"}:</label>
                                                                    <Field className="form-control" id="jobLocation" name="jobLocation" type="text" placeholder=" Akwa, Douala Cameroun " />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobStartingDate">{"Date de début de l'emploi"}:</label>
                                                                    <DatePicker 
                                                                        dateFormat="yyyy/MM/dd"
                                                                        className="form-control digits" 
                                                                        selected={startDate} 
                                                                        onChange={handleChange} 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobApplicationDelay">{"Délai de candidature"}:</label>
                                                                    <DatePicker 
                                                                        className="form-control digits" 
                                                                        selected={startDate1} 
                                                                        dateFormat="yyyy/MM/dd"
                                                                        onChange={handleChange1} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        
                                                        {/* <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="col-form-label pt-0">{BirthDate}</div>
                                                                <div className="form-group">
                                                                    <Typeahead
                                                                        id="basic-typeahead"
                                                                        labelKey="name"
                                                                        multiple={multiple}
                                                                        options={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                                                                        placeholder="Choose a Month..."
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group select-no-label">
                                                                    <Typeahead
                                                                        id="basic-typeahead"
                                                                        labelKey="name"
                                                                        multiple={multiple}
                                                                        options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14','15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']}
                                                                        placeholder="date"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group select-no-label">
                                                                    <Typeahead
                                                                        id="basic-typeahead"
                                                                        labelKey="name"
                                                                        multiple={multiple}
                                                                        options={['1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002','2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019']}
                                                                        placeholder="Year"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobType">{"Type d'emploi"}:</label>
                                                                    <Typeahead
                                                                        id="jobType"
                                                                        labelKey="name"
                                                                        multiple={false}
                                                                        onChange={(selected) => setJobType(selected)}
                                                                        options={['CDI', 'CDD', 'ACADEMIC_STAGE', 'PROFESSIONAL_STAGE']}
                                                                        placeholder="Job type"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDisponibility">{"Disponibilité de l'emploi"}:</label>
                                                                    <Typeahead
                                                                        id="jobDisponibility"
                                                                        labelKey="name1"
                                                                        onChange={(selected) => setJobDisponibility(selected) }
                                                                        multiple={false}
                                                                        options={['FULLTIME', 'PARTIALTIME']}
                                                                        placeholder="Job disponibility"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobCategory">{"Job type"}:</label>
                                                                    <Typeahead
                                                                        id="jobDisponibility"
                                                                        labelKey="name2"
                                                                        multiple={false}
                                                                        options={['FULLTIME', 'PARTIALTIME']}
                                                                        placeholder="Marketing"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobCategory">{"Catégorie d'emploi"}:</label>
                                                                    <Typeahead
                                                                        id="jobCategory"
                                                                        labelKey="name"
                                                                        multiple={false}
                                                                        options={categories}
                                                                        onChange={(selected) => setSelectedCategory(selected) }
                                                                        placeholder="Marketing"
                                                                        renderMenuItemChildren={(option, props) => (
                                                                            <Fragment>
                                                                                <span>{option.name}</span>
                                                                            </Fragment>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobImage">{"Image de l'emploi"}:</label>
                                                                    <input className="form-control" id="jobImage" type="file" onChange={handleChangeFile}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className='row'>
                                                            <div className='col'>
                                                                <div>
                                                                    <button 
                                                                    onClick={handleAddsectionModal}
                                                                    className="add-section-button" type="button">
                                                                        <Plus size={14}/> {" "}
                                                                        {"New Section"}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div> */}


<div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDescription">{"Description de l'emploi"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobDescription}
                                                                        config={
                                                                            {
                                                                                height:'600px'
                                                                            }
                                                                        }
                                                                        events={{
                                                                            "change": onChangeJobDescription
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDescriptionEn">{"Description de l'emploi en anglais"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobDescriptionEn}
                                                                        config={
                                                                            {
                                                                                height:'600px'
                                                                            }
                                                                        }
                                                                        events={{
                                                                            "change": onChangeJobDescriptionEn
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobDescriptionEn">{"Description de l'emploi en francais"}:<span className="font-danger">{"*"}</span></label>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={jobDescriptionFr}
                                                                        config={
                                                                            {
                                                                                height:'600px'
                                                                            }
                                                                        }
                                                                        events={{
                                                                            "change": onChangeJobDescriptionFr
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer">
                                                            <button className="btn btn-primary me-1" type="submit">
                                                                { isSubmiting && 
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            
                                                            } {" "}
                                                                {Submit}</button>
                                                            <Field className="btn btn-light" type="reset" value="Cancel" />
                                                        </div>
                                                    </Form>
                                                )
                                            }
                                        </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Modal isOpen={addSectionModalOpen} toggle={addSectionModal} size="lg">
                    <ModalHeader toggle={addSectionModal}>{"créer une section"}</ModalHeader>
                    <ModalBody>
                    <div className="form-bookmark needs-validation">
                        <div className="form-row">
                            <FormGroup className="col-md-12">
                                <Label>{"Titre par defaut de la section"}</Label>
                                <input value={ contentTitle } onChange={(e) => setContentTitle(e.target.value)} className="form-control" type="text" autoComplete="off" />
                            </FormGroup>
                            <FormGroup className="col-md-12">
                                <Label>{"Titre en anglais de la section"}</Label>
                                <input value={ contentTitleEn } onChange={(e) => setContentTitleEn(e.target.value)}  className="form-control" type="text" autoComplete="off" />
                            </FormGroup>
                            <FormGroup className="col-md-12">
                                <Label>{"Titre en Francais de la section"}</Label>
                                <input value={ contentTitleFr } onChange={(e) => setContentTitleFr(e.target.value)}  className="form-control" type="text" autoComplete="off" />
                            </FormGroup>
                            <br/>
                            <h2>
                                Contenu de la section
                            </h2>
                            <br/>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="jobResponsabilities">{"Job responsabilities"}:<span className="font-danger">{"*"}</span></label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={jobResponsabilities}
                                            events={{
                                                "change": onChangeJobResponsabilities
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button 
                            color="secondary" 
                            type='button' 
                            // onClick={handleEdit}
                            className="me-1">
                            {"Create"}
                        </Button>
                        <Button color="primary" onClick={addSectionModal}>{"Cancel"}</Button>
                    </div>
                    </ModalBody>
                </Modal> */}
                    
        </Fragment>
    );
};

export default NewJob;